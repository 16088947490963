import useAuth from '@hooks/useAuth';

const LogoutButton = () => {
  const { onLogout } = useAuth();

  return (
    <div onClick={onLogout} style={{ color: '#003595', cursor: 'pointer' }}>
      Log Out
    </div>
  );
};

export default LogoutButton;
