import { useMemo } from 'react';

import { useNavigate } from 'react-router';
import { Nav } from 'react-bootstrap';

import IconButton from '@components/Button/IconButton';
import useBreakpoint, { SIZE_XS } from '@hooks/useBreakpoint';

import './PatientDetailSidebar.scss';

interface IProps {
  numOfOrders: number;
  numOfDocuments: number;
}

const PatientDetailSidebar = ({ numOfOrders, numOfDocuments }: IProps) => {
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();

  const isWidthXs = useMemo(() => breakpoint === SIZE_XS, [breakpoint]);

  const handleReturn = () => {
    navigate('/requisitions');
  };

  return (
    <div className='d-flex flex-row flex-lg-column patient-detail-sidebar'>
      <IconButton
        variant='link'
        icon={['fas', 'arrow-left-long']}
        onClick={handleReturn}
        className='mt-lg-1 mb-lg-3 fw-medium return-btn'
        data-testid='patientDetailReturnBtn'
      >
        {isWidthXs ? '' : 'Return'}
      </IconButton>

      <Nav as='nav' aria-label='On this page'>
        <ul className='d-flex flex-row flex-lg-column ps-0 mb-0 text-start sections-navbar'>
          <li>
            <Nav.Link className='align-self-start' eventKey='orders' href='#orders'>
              Orders ({numOfOrders})
            </Nav.Link>
          </li>
          <li>
            <Nav.Link className='align-self-start' eventKey='documents' href='#documents'>
              Documents ({numOfDocuments})
            </Nav.Link>
          </li>
        </ul>
      </Nav>
    </div>
  );
};

export default PatientDetailSidebar;
