import Button from 'react-bootstrap/Button';
import './button.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function DefaultButton({ onClick, buttonLabel, styleClass, buttonStyling, type, ariaLabel, icon, disabled, ...props }) {
  return (
    <Button
      type={type}
      aria-label={ariaLabel}
      className={styleClass}
      style={buttonStyling}
      disabled={disabled}
      onClick={e => onClick(e)}
      {...props}
    >
      {icon ? <FontAwesomeIcon icon={icon} style={{ fontSize: '14px', marginRight: '5px' }} /> : null}
      {buttonLabel}
    </Button>
  );
}

export default DefaultButton;
