import { useState } from 'react';

import { Button } from 'react-bootstrap';

import { Requisition } from '@customTypes/requisition';
import { useRequisition } from '@hooks/useRequisition';

import OrderReceiptDialog from './OrderReceiptDialog';

/**
 * In future we may want to change name of this component.
 * Test (UI) = Order (API)
 */
import PatientDetailOrderCard from './PatientDetailOrderCard';

interface IProps {
  requisition: Requisition;
}

export default function PatientDetailOrder({ requisition }: IProps) {
  const { orders } = useRequisition(requisition);

  const [showOrderReceipt, setShowOrderReceipt] = useState(false);

  const onOrderReceipt = () => {
    setShowOrderReceipt(true);
  };

  return (
    <div className='bg-light rounded-3 p-2 patient-detail-order'>
      <div className='d-flex flex-column align-items-start order-header'>
        <span className='fs-body small fw-bold text-second'>Order ID:</span>
        <div className='d-flex align-items-center gap-3'>
          <p className='mb-0 fs-5 lh-xs fw-bold'>{requisition.requisition_id}</p>
          <Button as='a' variant='link' size='sm' onClick={onOrderReceipt}>
            Order Receipt
          </Button>
        </div>
      </div>

      <div className='d-flex flex-column flex-wrap flex-md-row gap-4 mt-2 order-cards'>
        {orders.map(order => (
          <PatientDetailOrderCard key={order.product_id} order={order} requisitionId={requisition.requisition_id} />
        ))}
      </div>

      {showOrderReceipt && <OrderReceiptDialog requisition={requisition} onClose={() => setShowOrderReceipt(false)} />}
    </div>
  );
}
