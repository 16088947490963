import { lazy, Suspense, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Card, Stack } from 'react-bootstrap';

import IconButton from '@components/Button/IconButton';

import CancelConfirmDialog from './components/CancelConfirmDialog/CancelConfirmDialog';
import './RequisitionCreate.scss';

const RequisitionJSONSchemaForm = lazy(
  () => import('./components/RequisitionJSONSchemaForm/RequisitionJSONSchemaForm'),
);

const RequisitionCreate = () => {
  const navigate = useNavigate();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const moveToRequisitions = () => {
    navigate('/requisitions');
  };

  return (
    <Suspense>
      <Stack gap={4} className='py-4 create-requisition-container' data-testid='newOrderPage'>
        <Breadcrumb listProps={{ className: 'mb-0' }}>
          <Breadcrumb.Item onClick={moveToRequisitions}>Orders and Reports</Breadcrumb.Item>
          <Breadcrumb.Item active>New Order</Breadcrumb.Item>
        </Breadcrumb>
        <Card as='section' className='text-start px-4 pb-4 pt-2 box-shadow-50 position-static'>
          <Card.Title as='h1' className='mt-md-1 fw-bold page-title'>
            New Order
          </Card.Title>
          <Card.Subtitle className='mt-md-1 fs-body fw-normal text-second mb-0 page-subtitle' as='p'>
            Fill out the form in each step as provided. Use the Next button to continue to the next step, and the Submit
            button when all steps are completed. Some product selections may not require additional information.
          </Card.Subtitle>
          <Card.Body className='p-0 pt-4'>
            <RequisitionJSONSchemaForm />
          </Card.Body>
          <Card.Footer className='bg-white p-0 pt-3 mt-4 border-top'>
            <IconButton
              variant='outline-dark'
              icon={['fas', 'arrow-left-long']}
              onClick={() => setShowConfirmDialog(true)}
              data-testid='cancelAndReturnBtn'
            >
              Cancel and return
            </IconButton>
          </Card.Footer>
        </Card>
      </Stack>
      {showConfirmDialog && (
        <CancelConfirmDialog onClose={() => setShowConfirmDialog(false)} onConfirm={moveToRequisitions} />
      )}
    </Suspense>
  );
};

export default RequisitionCreate;
