import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Card, Stack } from 'react-bootstrap';

import IconButton from '@components/Button/IconButton';
import Placeholder from '@components/Placeholder/Placeholder';
import { Order, OrderStatus } from '@customTypes/order';
import { isOrderReported } from '@helpers/order';
import { fetchRequisitions } from '@store/actions';
import { Context } from '@store/store';

import RequisitionTable from './components/RequisitionTable/RequisitionTable';

import './Requisitions.scss';

const Requisitions = () => {
  const [state, dispatch] = useContext(Context);

  const [loading, setLoading] = useState(state.requisitions === undefined);
  const [error, setError] = useState<string | null>(null);
  const [showPatientName, setShowPatientName] = useState(false);

  const orderStatus: Array<{ title: string; value: number }> = useMemo(() => {
    const orders: Order[] = [];

    state.requisitions?.forEach(requisition => {
      orders.push(...requisition.orders);
    });

    const outstandingOrders = orders.filter(
      order =>
        ![OrderStatus.COMPLETED, OrderStatus.CANCELED, OrderStatus.DENIED, OrderStatus.FAILED].includes(order.status),
    );
    const reportedOrders = orders.filter(isOrderReported);

    return [
      {
        title: 'Total Orders',
        value: orders.length,
      },
      {
        title: 'Outstanding Orders',
        value: outstandingOrders.length,
      },
      {
        title: 'Reported Orders',
        value: reportedOrders.length,
      },
    ];
  }, [state.requisitions]);

  const loadRequisitions = useCallback(() => {
    /**
     * Do not refetch requisitions if data already exist
     */
    if (state.currentClinician && state.requisitions === undefined) {
      setLoading(true);
      setError(null);

      fetchRequisitions()
        .then(requisitions => {
          dispatch({ type: 'SET_REQUISITIONS', payload: requisitions });
        })
        .catch(err => {
          setError(err?.toString() ?? 'Server Error');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dispatch, state.currentClinician, state.requisitions]);

  useEffect(() => {
    loadRequisitions();
  }, [loadRequisitions]);

  return (
    <Stack gap={4} className='py-4' data-testid='requisitionsPage'>
      <section className='d-flex flex-column text-start rounded-1 bg-white box-shadow-50 order-report-section'>
        <h1 className='fw-bold page-title'>Your orders and reports</h1>
        <p className='mt-md-1 fs-body fw-normal text-second mb-0 page-subtitle'>
          View and search orders to download reports and create requisitions
        </p>
        <div className='p-0'>
          <div className='d-flex flex-column flex-sm-row mt-3 mt-md-4 justify-content-center order-cards'>
            {orderStatus.map(status => (
              <Card key={status.title} className='fw-bold bg-white order-status border-1'>
                <Card.Body className='py-2 py-md-3'>
                  {loading && (
                    <div className='d-flex flex-column gap-3 order-status--title'>
                      <Placeholder size='xl' />
                      <Placeholder />
                    </div>
                  )}
                  {!loading && (
                    <>
                      <h2 className={`fs-1 fw-bold order-status--value ${error ? 'error' : ''}`}>
                        {error ? '...' : status.value}
                      </h2>
                      <p className='fs-5 mb-0 order-status--title'>{status.title}</p>
                    </>
                  )}
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
      </section>
      {loading ? (
        <Placeholder size='sm' />
      ) : (
        <IconButton
          variant='outline-primary'
          icon={['fas', showPatientName ? 'eye' : 'eye-slash']}
          size='sm'
          onClick={() => setShowPatientName(showPatientName => !showPatientName)}
          role='toolbar'
          aria-label='button toggle for patient name privacy'
          className='align-self-start patient-toggle-btn'
          disabled={!!error}
        >
          Patient name
        </IconButton>
      )}

      <RequisitionTable
        loading={loading}
        loadingError={error}
        showPatientName={showPatientName}
        data={state.requisitions || []}
        onRetry={loadRequisitions}
      />
    </Stack>
  );
};

export default Requisitions;
