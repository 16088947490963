import { useMemo } from 'react';

import { get, kebabCase, startCase } from 'lodash';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faUserMinus } from '@fortawesome/pro-duotone-svg-icons';

import FormField from '@components/Form/FormField';
import { ROLE_PLACEHOLDER } from '@constants/constants';
import { ISignupForm, UserRole } from '@customTypes/user';

import './AdditionalAccountFieldset.scss';
import { useFormikContext } from 'formik';

interface IAdditionalAccountFieldsetProps {
  fsetIndex: number;
  remove: () => void;
}

export default function AdditionalAccountFieldset({ fsetIndex, remove }: IAdditionalAccountFieldsetProps) {
  const { handleChange, handleBlur, values } = useFormikContext<ISignupForm>();
  const indexPlus = fsetIndex + 1;
  const prefix = useMemo(() => `teammates.${fsetIndex}.`, [fsetIndex]);
  const role = useMemo(() => get(values, `${prefix}role`), [values, prefix]);

  return (
    <fieldset id={'fset_AdditionalAccount_' + indexPlus} className='fset fset-additional-account'>
      <legend className='legend'>Account {indexPlus}</legend>
      <Stack className='gap-12'>
        <Row>
          <FormField cols={4} name={`${prefix}name.first` as never} label='First name' required />
          <FormField cols={4} name={`${prefix}name.middle` as never} label='Middle name or initial' />
          <FormField cols={4} name={`${prefix}name.last` as never} label='Last name' required />
        </Row>
        <Row>
          <Form.Group as={Col} controlId={'select_AccountRole_' + indexPlus} className='col-12 col-lg-4'>
            <Form.Label className='required'>Role</Form.Label>
            <Form.Select name={`${prefix}role`} onChange={handleChange} onBlur={handleBlur} required>
              <option value={ROLE_PLACEHOLDER} disabled={!!role}>
                {ROLE_PLACEHOLDER}
              </option>
              {Object.values(UserRole).map((opt, index) => (
                <option key={'select_AccountRole_opt' + index} value={opt}>
                  {startCase(kebabCase(opt))}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback as='small' role='alert' type='invalid'>
              Role selection is required.
            </Form.Control.Feedback>
          </Form.Group>

          <FormField
            cols={4}
            name={`${prefix}npi` as never}
            required={role === UserRole.ORDERING_PROVIDER}
            label='NPI'
            helperText={
              role === UserRole.ORDERING_PROVIDER
                ? 'NPI required for selected role'
                : !role
                  ? 'Select a role before adding NPI'
                  : 'NPI is optional for selected role'
            }
            disabled={!role}
          />
          <FormField
            cols={4}
            name={`${prefix}name.credentials` as never}
            label='Credentials'
            helperText='e.g. MD, CNP'
          />
        </Row>

        <Row>
          <FormField cols={4} name={`${prefix}email` as never} type='email' label='Email' required />
          <FormField
            cols={4}
            name={`${prefix}phone` as never}
            type='tel'
            label='Phone'
            helperText='e.g. +18318675309'
          />
          <FormField cols={4} name={`${prefix}fax` as never} type='tel' label='Fax' helperText='e.g. +18318675309' />
        </Row>

        {!fsetIndex ? null : (
          <div className='pt-2'>
            <Button
              id={'button_RemoveAccount_' + indexPlus}
              aria-controls={'fset_AdditionalAccount_' + indexPlus}
              variant='outline-primary'
              onClick={remove}
            >
              <FontAwesomeIcon icon={faUserMinus as IconDefinition} fontSize={16} className='me-2' />
              Remove
            </Button>
          </div>
        )}
      </Stack>
    </fieldset>
  );
}
