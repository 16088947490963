import { Alert, AlertProps } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faXmarkLarge } from '@fortawesome/pro-solid-svg-icons';

import { SUPPORT_EMAIL } from '@constants/constants';

interface ErrorAlertProps extends AlertProps {
  onClose: () => void;
}

export default function ErrorAlert({ children, show = false, onClose, ...otherProps }: ErrorAlertProps) {
  return (
    <Alert show={show} variant='danger' className='mb-0' {...otherProps}>
      <div className='d-flex align-items-center w-100 gap-12'>
        <FontAwesomeIcon icon={faTriangleExclamation} fontSize={16} />
        <p className='flex-grow-1 mb-0'>
          {children ?? (
            <>
              Something went wrong. Try again, or contact <span className='fw-bold'>{SUPPORT_EMAIL}</span> for
              assistance.
            </>
          )}
        </p>

        <FontAwesomeIcon
          data-testid='alertCloseButton'
          onClick={onClose}
          icon={faXmarkLarge as IconDefinition}
          fontSize={16}
          role='button'
        />
      </div>
    </Alert>
  );
}
