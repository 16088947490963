import { useMemo } from 'react';
import { Placeholder as RBPlaceholder, PlaceholderProps as RBPlaceholderProps } from 'react-bootstrap';

import './Placeholder.scss';

interface PlaceholderProps extends Omit<RBPlaceholderProps, 'size'> {
  size?: 'xs' | 'sm' | 'lg' | 'xl';
}

export default function Placeholder(props: PlaceholderProps) {
  const { animation, className = '', size, ...otherProps } = props ?? {};
  const sizeClass = useMemo(() => (size === 'xl' ? 'placeholder-xl' : size ? `placeholder-${size}` : ''), [size]);

  return (
    <RBPlaceholder animation={animation ?? 'glow'} className={`${className} ${sizeClass} d-block placeholder-root`}>
      <RBPlaceholder className='w-100 h-100' bg='primary' {...otherProps} />
    </RBPlaceholder>
  );
}
