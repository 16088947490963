import { useCallback, useContext, useMemo } from 'react';

import dayjs from 'dayjs';
import { Button, Modal, Stack, Table } from 'react-bootstrap';

import ErrorAlert from '@components/Alerts/ErrorAlert';
import IconButton from '@components/Button/IconButton';
import { DATE_FORMAT, ORDER_STATUS_MAPPING } from '@constants/constants';
import { ExtendedOrder, OrderStatus } from '@customTypes/order';
import { Requisition } from '@customTypes/requisition';
import useBreakpoint, { SIZE_XS, SIZE_SM, SIZE_MD } from '@hooks/useBreakpoint';
import { useClinician } from '@hooks/useClinician';
import { useDownload } from '@hooks/useDownload';
import { useRequisition } from '@hooks/useRequisition';
import { Context } from '@store/store';

import './RequisitionDetailDialog.scss';

interface RequisitionDetailDialogProps {
  requisition: Requisition | null;
  onClose: () => void;
}

const RequisitionDetailDialog: React.FC<RequisitionDetailDialogProps> = ({ requisition, onClose }) => {
  const [state] = useContext(Context);
  const { onDownload, downloadError, setDownloadError } = useDownload();
  const { patientName, requisitionId, requisitionDate, orders, isCanceled, isCompleted } = useRequisition(requisition);
  const { clinicianName } = useClinician(state.currentClinician);
  const breakpoint = useBreakpoint();

  const basicInfo: Array<{ label: string; value?: string }> = useMemo(
    () => [
      {
        label: 'Req. ID:',
        value: requisitionId,
      },
      {
        label: 'Req. Date:',
        value: requisitionDate,
      },
      {
        label: 'Clinician:',
        value: clinicianName,
      },
    ],
    [requisitionId, requisitionDate, clinicianName],
  );

  const reportedLabel = useMemo(() => {
    if ([SIZE_XS, SIZE_SM, SIZE_MD].includes(breakpoint)) {
      return 'Reported';
    }

    return 'Reported Date';
  }, [breakpoint]);

  const description = useMemo(() => {
    return isCanceled
      ? 'No reports will be created.'
      : 'A copy of the report will be available for download when the order is marked as Reported.';
  }, [isCanceled]);

  const downloadButton = useCallback(
    (order: ExtendedOrder) => {
      if ([OrderStatus.CANCELED, OrderStatus.DENIED, OrderStatus.FAILED].includes(order.status)) {
        return 'None';
      }
      if (!order.isReported) {
        return 'Report Pending';
      }

      return (
        <IconButton
          variant='outline-primary'
          icon={['fas', 'circle-arrow-down']}
          size='sm'
          onClick={() => onDownload(order, requisitionId)}
          data-testid='downloadReport'
        >
          Download
        </IconButton>
      );
    },
    [onDownload, requisitionId],
  );

  if (!requisition) return null;

  return (
    <Modal
      show
      onHide={onClose}
      size='lg'
      id='requisition-detail-dialog'
      aria-labelledby={`modal-title_${requisitionId}`}
      data-testid='requisitionDetailDialog'
    >
      <Modal.Header closeButton>
        <Modal.Title as='h1' id={`modal-title_${requisitionId}`} className='fs-5 fw-medium lh-sm'>
          Requisition Details for {patientName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack className='dialog-content'>
          <ErrorAlert show={!!downloadError} onClose={() => setDownloadError(false)} />
          <div className='d-flex flex-wrap justify-content-center pb-2 border-bottom basic-infos'>
            {basicInfo.map(info => (
              <p key={info.label} className='d-flex justify-content-center mb-0 fs-body text-nowrap'>
                <span className='fw-bold me-1'>{info.label}</span>
                {info.value}
              </p>
            ))}
          </div>
          {!isCompleted && (
            <p className='mb-0 text-center' data-testid='requisitionInformativeText'>
              {description}
            </p>
          )}

          <Table responsive id='orders-table' className='mb-0 orders-table' data-testid='ordersTable'>
            <thead>
              <tr>
                <th>Test Name</th>
                <th>{reportedLabel}</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.map(order => (
                <tr key={order.order_id}>
                  <td className='text-wrap'>{order.product_name}</td>
                  <td>
                    {order.isReported && order.reported_at ? dayjs.utc(order.reported_at).format(DATE_FORMAT) : ''}
                  </td>
                  <td>{ORDER_STATUS_MAPPING[order.status]?.label}</td>
                  <td>{downloadButton(order)}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className='py-3 border-top other-download-documents' data-testid='requisitionClinicalSumaryLetter'>
            <p className='mb-2 fw-bold'>Other document(s) for download:</p>
            {!requisition.clinical_summary_letter && <p className='mb-0'>None available at this time.</p>}
            {!!requisition.clinical_summary_letter && (
              <ul className='mb-0 ps-4'>
                <li>
                  <a
                    href={requisition.clinical_summary_letter.uri}
                    download={requisition.clinical_summary_letter.description}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Clinical Summary Letter
                  </a>
                  <p className='text-second mb-0'>
                    {dayjs.utc(requisition.clinical_summary_letter.completed_on).format(DATE_FORMAT)}
                  </p>
                </li>
              </ul>
            )}
          </div>
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose} data-testid='closeRequisitionDetailDialog'>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RequisitionDetailDialog;
