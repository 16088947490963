import { useNavigate } from 'react-router-dom';
import { Button as RBButton } from 'react-bootstrap';

import loginLgImage from '@assets/images/login-images/login-lg.webp';
import loginXlImage from '@assets/images/login-images/login-xl.webp';
import loginXsImage from '@assets/images/login-images/login-xs.webp';
import loginSmImage from '@assets/images/login-images/login-sm.webp';
import loginMdImage from '@assets/images/login-images/login-md.webp';
import Header from '@components/Header/Header';
import Button from '@components/Button/button';
import useAuth from '@hooks/useAuth';

import './login.scss';

function Login() {
  const { isLoggedIn, onLogin } = useAuth();
  const navigate = useNavigate();

  return (
    <div data-testid='login_page'>
      <Header />
      <div className='login-page-wrapper'>
        <div className='login-left-half'>
          <div className='left-half-content'>
            <div className='login-title'>Empowering Health Decisions through Genomics</div>
            <Button
              onClick={onLogin}
              buttonLabel={isLoggedIn ? 'Go to Home' : 'Login'}
              styleClass='me-0 loginButtonStyling'
              data-testid='loginBtn'
            />
            {/* Hide a register button */}
            {!isLoggedIn && false && (
              <RBButton as='a' variant='outline-primary' size='sm' className='mt-4' onClick={() => navigate('/signup')}>
                Sign up for an account
              </RBButton>
            )}
          </div>
        </div>
        <div className='login-right-half'>
          <img
            data-sizes='auto'
            alt='doctor seated at desk and smiling at patient'
            src={loginXlImage}
            srcSet={`
              ${loginXsImage} 440w,
              ${loginSmImage} 767w,
              ${loginMdImage} 991w,
              ${loginLgImage} 1000w,
              ${loginXlImage} 1236w
            `}
            className='login-img'
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
