import { ReactComponent as FullLogoSvg } from '@assets/images/full_logo.svg';
import CurrentUserBanner from '@components/CurrentUserBanner/currentUserBanner';
import LogoutButton from '@components/LogoutButton/logoutButton';
import useAuth from '@hooks/useAuth';

import './header.scss';

function Header() {
  const { isLoggedIn } = useAuth();

  return (
    <div className='header_banner_wrapper'>
      <div className='header-wrapper'>
        <FullLogoSvg alt='logo' title='logo' className='logo-styling' />
        {isLoggedIn ? <LogoutButton /> : null}
      </div>
      {isLoggedIn ? <CurrentUserBanner /> : null}
    </div>
  );
}

export default Header;
