import { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconButton from '@components/Button/IconButton';
import RequisitionReceiptContent from '@components/Requisition/RequisitionReceiptContent/RequisitionReceiptContent';
import { Context } from '@store/store';

import './RequisitionComplete.scss';

const RequisitionComplete = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context);

  const newRequisition = useMemo(() => state.newRequisition, [state.newRequisition]);

  const moveToRequisitions = () => {
    navigate('/requisitions');
  };

  const printConfirmation = () => {
    window.print();
  };

  useEffect(() => {
    return () => {
      dispatch({ type: 'SET_NEW_REQUISITION', payload: null });
    };
  }, [dispatch]);

  if (!newRequisition) {
    return null;
  }

  return (
    <Stack gap={4} className='py-4 requisition-complete-container' data-testid='requisitionCompletePage'>
      <Breadcrumb listProps={{ className: 'mb-0' }}>
        <Breadcrumb.Item onClick={moveToRequisitions}>Orders and Reports</Breadcrumb.Item>
        <Breadcrumb.Item active>Order Complete</Breadcrumb.Item>
      </Breadcrumb>
      <section className='d-flex flex-column bg-white px-md-4 pb-4 pt-md-2'>
        <FontAwesomeIcon icon={['fas', 'circle-check']} color='primary' fontSize={40} className='me-2 d-print-none' />
        <h1 className='mb-0 fw-semibold fs-3'>Order Confirmation</h1>
        <div className='p-4 pb-0 d-flex flex-column align-items-center gap-4'>
          <RequisitionReceiptContent requisition={newRequisition} />
          <Stack
            direction='horizontal'
            gap={3}
            className='justify-content-center mt-3 requisition-complete-actions d-print-none'
          >
            <IconButton variant='outline-primary' onClick={printConfirmation} data-testid='printConfirmationBtn'>
              Print Confirmation
            </IconButton>
            <IconButton variant='primary' onClick={moveToRequisitions} data-testid='returnToRequisitionsBtn'>
              Return to Orders and Reports
            </IconButton>
          </Stack>
        </div>
      </section>
    </Stack>
  );
};

export default RequisitionComplete;
