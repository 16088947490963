import { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import { useParams, useNavigate } from 'react-router';
import { Badge, Stack } from 'react-bootstrap';

import LeftContent from '@components/Layout/LeftContent/LeftContent';
import RightContent from '@components/Layout/RightContent/RightContent';
import { Requisition } from '@customTypes/requisition';
import { isOrderReported } from '@helpers/order';
import usePatientDetail from '@hooks/usePatientDetail';
import { useRequisition } from '@hooks/useRequisition';

import PatientDetailDocuments from './components/PatientDetailDocuments/PatientDetailDocuments';
import PatientDetailOrders from './components/PatientDetailOrders/PatientDetailOrders';
import PatientDetailSidebar from './components/PatientDetailSidebar/PatientDetailSidebar';

import './PatientDetail.scss';
import { getPatientDetail } from '@store/actions';
import { IPatientDetailRes } from '@customTypes/patient';

const PatientDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const patientId = useMemo(() => params?.id, [params]);
  // Array of requisitions which includes at least one order to download
  const [filteredRequisitions, setFilteredRequisitions] = useState<Requisition[]>([]);
  const [patientDetail, setPatientDetail] = useState<IPatientDetailRes | null>(null);
  const isUnder18 = useMemo(() => {
    if (!patientDetail?.demographics.dob) return false;

    return dayjs().diff(patientDetail?.demographics.dob, 'year') < 18;
  }, [patientDetail?.demographics.dob]);

  const { requisitions } = usePatientDetail(patientId);
  const { patientName } = useRequisition(requisitions?.[0]);

  const patientInfoCells = useMemo(
    () => [
      {
        label: 'Date of Birth',
        value: patientDetail?.demographics.dob,
      },
      {
        label: 'Sex',
        value: patientDetail?.demographics.sex,
      },
    ],
    [patientDetail],
  );
  const totalDocuments = useMemo(() => {
    let totalNum = 0;

    filteredRequisitions.forEach(requisition => {
      totalNum += requisition.orders.length;

      if (requisition.clinical_summary_letter?.uri) {
        totalNum += 1;
      }
    });

    return totalNum;
  }, [filteredRequisitions]);

  const loadData = useCallback(async () => {
    if (patientId) {
      const res = await getPatientDetail(patientId);
      setPatientDetail(res);
    }
  }, [patientId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    setFilteredRequisitions(() =>
      orderBy(
        requisitions
          .map(requisition => ({
            ...requisition,
            orders: requisition.orders.filter(order => isOrderReported(order)),
          }))
          .filter(requisition => requisition.orders.length > 0),
        ['requisition_id'],
        ['desc'],
      ),
    );
  }, [requisitions]);

  if (!patientId) {
    navigate('/requisitions');
    return null;
  }

  return (
    <div className='d-flex flex-column flex-lg-row page-content patient-detail-page'>
      <LeftContent>
        <PatientDetailSidebar numOfOrders={requisitions.length} numOfDocuments={totalDocuments} />
      </LeftContent>
      <RightContent>
        <Stack gap={4} className='patient-detail-content'>
          <Stack as='section' className='box-shadow-50 bg-white rounded-1 patient-detail-info'>
            {isUnder18 && (
              <Badge as='p' bg='info' className='align-self-start mb-0 fs-body patient-age-min'>
                Patient is under 18 years old.
              </Badge>
            )}
            <h2 className='text-start mb-3 mb-lg-4 fw-bold page-title'>{patientName}</h2>
            <ul className='d-flex gap-3 ps-0 mb-0 patient-detail-cells'>
              {patientInfoCells.map(infoCell => (
                <li key={infoCell.label} className='d-flex flex-column align-items-start patient-detail-cell'>
                  <p className='fs-6 mb-0 text-second cell-label'>{infoCell.label}</p>
                  <p className='fs-5 lh-xs fw-medium mb-0 cell-value'>{infoCell.value}</p>
                </li>
              ))}
            </ul>
          </Stack>

          <PatientDetailOrders requisitions={requisitions} />
          <PatientDetailDocuments requisitions={filteredRequisitions} numOfDocuments={totalDocuments} />
        </Stack>
      </RightContent>
    </div>
  );
};

export default PatientDetail;
