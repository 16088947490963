import './currentUserBanner.scss';
import { useContext } from 'react';
import { Context } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserDoctor } from '@fortawesome/free-solid-svg-icons';

function CurrentUserBanner() {
  const [state] = useContext(Context);

  function formatClinicianName(name) {
    const first = name.first.charAt(0) + name.first.slice(1).toLowerCase();
    const middle = name.middle ? name.middle.charAt(0) + name.middle.slice(1).toLowerCase() : '';
    const last = name.last.charAt(0) + name.last.slice(1).toLowerCase();
    const cred = name.credentials;
    let formatted = first;

    if (middle) formatted += ' ' + middle;
    formatted += ' ' + last;
    if (cred) formatted += ', ' + cred;

    return formatted;
  }

  return (
    <div>
      {state.currentClinician && state.currentClinician.name ? (
        <div className='current_clinician_wrapper'>
          <div>
            <FontAwesomeIcon icon={faUserDoctor} style={{ margin: '5px', fontSize: '22px' }} />
          </div>
          <div style={{ marginLeft: '5px' }}>Logged in as:</div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div style={{ fontWeight: '700', marginLeft: '5px', color: '#003595' }}>
              {formatClinicianName(state.currentClinician.name)}
            </div>
            <div style={{ fontWeight: '500', marginLeft: '5px', fontSize: '14px' }}>
              {state.currentClinician.clinic_name}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CurrentUserBanner;
