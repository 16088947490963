import { useMemo } from 'react';

import dayjs from 'dayjs';

import { Clinician } from '@customTypes/clinician';
import { ExtendedOrder, OrderStatus } from '@customTypes/order';
import { Requisition } from '@customTypes/requisition';
import { getClinicianName } from '@helpers/clinician';
import { isOrderReported } from '@helpers/order';
import { getPatientName } from '@helpers/patient';
import { getRequisitionStatus } from '@helpers/requisition';

interface UseRequisitionResult {
  clinicians: Array<Clinician & { formattedName: string }>;
  patientName: string;
  requisitionId: string;
  requisitionDate: string;
  orders: ExtendedOrder[];
  requisitionStatus: string | null;
  isCanceled: boolean;
  isCompleted: boolean;
}

export const useRequisition = (requisition?: Requisition | null): UseRequisitionResult => {
  const patientName = useMemo(
    () => getPatientName(requisition?.subject?.name ?? requisition?.name),
    [requisition?.name, requisition?.subject],
  );
  const requisitionId = useMemo(() => requisition?.requisition_id ?? '', [requisition?.requisition_id]);
  const requisitionDate = useMemo(() => {
    if (!requisition?.submitted_at) {
      return '';
    }

    return dayjs.utc(requisition.submitted_at).format('MM/DD/YYYY');
  }, [requisition?.submitted_at]);

  const orders = useMemo(
    () =>
      (requisition?.orders || []).map(order => ({
        ...order,
        created_at: dayjs.utc(order.created_at).format('MM/DD/YYYY'),
        isReported: isOrderReported(order),
      })),
    [requisition?.orders],
  );

  const requisitionStatus: string | null = useMemo(() => getRequisitionStatus(requisition), [requisition]);

  const isCanceled = useMemo(
    () => orders.every(order => [OrderStatus.CANCELED, OrderStatus.DENIED, OrderStatus.FAILED].includes(order.status)),
    [orders],
  );

  const isCompleted = useMemo(() => orders.every(isOrderReported), [orders]);

  const clinicians = useMemo(
    () =>
      (requisition?.clinicians || []).map(clinician => ({
        ...clinician,
        formattedName: getClinicianName(clinician),
      })),
    [requisition],
  );

  return {
    clinicians,
    patientName,
    requisitionId,
    requisitionDate,
    orders,
    requisitionStatus,
    isCanceled,
    isCompleted,
  };
};
